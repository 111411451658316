.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

main {
    margin-top: 70px; /* Adjust this value to match the height of the header */
}