.video-container {
    position: relative;
    padding-top: 56.25%; /* 16:9 aspect ratio */
    height: 0;
    overflow: hidden;
    aspect-ratio: 16 / 9; /* maintains a 16:9 aspect ratio */
  }
  
  .video-element {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }

  .main {
    margin-top: 5rem;
  }

  body {
    overflow-x: hidden;
}